/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@include mat.core();
$my-primary: mat.define-palette(mat.$red-palette, 900);
$my-accent: mat.define-palette(mat.$red-palette, A200, A100, A400);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

@import "fonts";

html {
  font-size: 16px;
  @include mat.all-component-themes($my-theme);
  scroll-behavior: smooth;

}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: black;
}
div#heroBg {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 1080px;
  z-index: -10;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: black;
  background-blend-mode: overlay;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: f1, Roboto, "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  > span {
    display: inline-block;
  }
}
.error, .ok-msg {
  padding: 0.5em;
  border-radius: 0.25em;
  color: black;
  box-shadow: 0 0 4px white;
  &.error {
    background-color: #ff00002b;
    border: 1px solid red;
  }
  &.ok-msg {
    background-color: rgba(26, 255, 0, 0.17);
    border: 1px solid green;
  }
}
a.disabled {
  pointer-events: none;
  opacity: 0.7;
  cursor: default;
}
.prodCard {
  background-color: whitesmoke;
  border-radius: 1em;
  border: 1px solid #eee;
  padding: 2em;
  box-shadow: 2px 1px 5px #ccc;
  p {
    text-transform: capitalize;
  }
}
.rowheader,
.columnheader {
  font-weight: bold;
  text-transform: capitalize;
}
.searchResTable {
  display: grid;
  grid-template-columns: auto auto;

  &.valsRight {
    .value {
      text-align: right;
    }
    .columnheader {
      text-align: right;
    }
  }

  .value {
    text-transform: capitalize;
  }

}
.clickable {
  cursor: pointer;
}
tr.clickable:hover {
  background-color: rgba(153, 50, 204, 0.1);
}

.snack-error {
  //--mdc-snackbar-container-color: #ff5e84;
  --mdc-snackbar-supporting-text-color: #ff6464;
}
.snack-ok {
  --mdc-snackbar-container-color: #53b649;
  --mdc-snackbar-supporting-text-color: #fff;
}
h1 {
  font-family: f1, sans-serif;
  grid-row: 2;
  font-size: 2.5em;
  @media screen and (max-width: 700px) {
    font-size: 2em;
  }
  text-align: center;
}
h2 {
  font-size: 2em;
  @media screen and (max-width: 700px) {
    font-size: 1.5em;
  }
}
h3 {
  font-size: 1.7em;
  @media screen and (max-width: 700px) {
    font-size: 1.2em;
  }
}
.euro {
  &:before {
    content: "€ ";
  }
}
.currencyAsterisk {
  font-size: max(12px, 0.75em);
  color: #999;
  font-style: italic;
}

a.mdc-button,button.mdc-button {
  font-family: f1, Roboto, "Helvetica Neue", sans-serif;
}
.c2aLarge {
  transform: scale(1.5);
}
.singleCol {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
}
.doubleCol {
  display: grid;
  grid-template-columns: repeat(2, auto);
}
.tripleCol {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.custom-mat-dialog {
  @media (max-width: 599px) {
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100%;
  }
}
mat-dialog-actions, mat-toolbar {
  .spacer {
    flex: 1 1 auto;
  }
}
.hidden {
  display: none !important;
}
.print-only {
  display: none !important;
}
.ngx-cc {
  button {
    background-color: #b71c1c !important;
    color: white !important;
  }
  .text--link, a {
    color: #b71c1c !important;
    text-decoration: none !important;
  }
}
.backdrop-blur {
  backdrop-filter: blur(0.3rem);
}
.emphasisBorder {
  box-shadow: 0 0 3em black;
}
.nowrap {
  white-space: nowrap;
}
@import "print.scss";
