@media print {

  header, .header, app-nav, div#heroBg, section:not(.page-content, .pageContent, .copyright, .hero), div.zsiq_floatmain, .noprint {
    display: none !important;
  }
  body, .page-content, header {
    background: transparent !important;
  }
  header.print-only {
    display: flex !important;
    justify-content: center;
    img.logo {
      width: 12cm  !important;
      height: auto  !important;
    }
  }
}
