@font-face {
  font-family: 'f1';
  src: url('/assets/fonts/Formula1-Bold.woff')  format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'f1';
  src: url('/assets/fonts/Formula1-Regular.woff')  format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'f1';
  src: url('/assets/fonts/Formula1-Wide.woff')  format('woff');
  font-weight: 800;
  font-style: normal;
}
